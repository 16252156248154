import React, { useEffect, useState } from "react";
import WhiteArrow from "../images/desktop-arrow-white.svg";
import BlackArrow from "../images/desktop-arrow-black.svg";

const Button = ({ type, text, location, target, device }) => {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 650px)").matches
    );

    useEffect(() => {
        window
            .matchMedia("(min-width: 650px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);

    return (
        <a href={location} target={target}>
            {matches && (
                <button className={type}>
                    <div className="items">
                        <p>{text}</p>
                        <img
                            src={type === "black" ? WhiteArrow : BlackArrow}
                            alt="arrow"
                        />
                    </div>
                </button>
            )}
            {!matches && (
                <button className={type + " mobile"}>
                    <div className="items">
                        <p>{text}</p>
                        <img
                            src={type === "black" ? WhiteArrow : BlackArrow}
                            alt="arrow"
                        />
                    </div>
                </button>
            )}
        </a>
    );
};

export default Button;
