import React from "react";
import Facebook from "../images/footer/footer-fb.svg";
import Instagram from "../images/footer/footer-insta.svg";
import Tiktok from "../images/footer/footer-tiktok.svg";
import ParagraphFont from "./ParagraphFont";

const Footer = () => {
    return (
        <footer>
            <div className="footerContents">
                <div className="socialMediaContainer">
                    <a
                        href="https://www.facebook.com/profile.php?id=100077501333389"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="facebook"
                            src={Facebook}
                            alt="facebook"
                        />
                    </a>
                    <a
                        href="https://www.instagram.com/thefuntones/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={Instagram}
                            className="instagram"
                            alt="instagram"
                        />
                    </a>
                    <a
                        href="https://www.tiktok.com/@thefuntones"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={Tiktok} alt="tiktok" className="instagram" />
                    </a>
                </div>
                <div className="titles">
                    <ParagraphFont
                        text="We acknowledge the Aboriginal and Torres Strait Island people as the traditional custodians of the lands where we live, learn and work. We pay our respects to Elders, past, present and emerging.
"
                    />
                    <p className="pfont copyright">© The Funtones</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
