import React from "react";
import SecondaryFont from "./SecondaryFont";
import ParagraphFont from "./ParagraphFont";

const Section = ({ title, paragraph }) => {
    return (
        <div className="title">
            <SecondaryFont text={title} />
            <ParagraphFont text={paragraph} />
        </div>
    );
};

export default Section;
