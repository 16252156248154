import React from "react";
import TitleContainer from "../components/TitleContainer";
import RainbowBackground from "../components/RainbowBackground";
import RainbowOne from "../images/desktop-rainbow-1.png";
import RainbowThree from "../images/desktop-rainbow-3.png";
import Accordion from "../components/Accordion";
import { Helmet } from "react-helmet";

const ForParents = ({ firstRef, firstRefVisible }) => {
    // title
    const TITLE = "The Funtones | For Adults";

    return (
        <div className="forParents">
            <>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
            </>
            <TitleContainer
                firstRef={firstRef}
                firstRefVisible={firstRefVisible}
                text="For adults."
            />
            <div className="sections">
                <section>
                    <Accordion
                        firstRef={firstRef}
                        firstRefVisible={firstRefVisible}
                    />
                </section>
            </div>
            <RainbowBackground type={RainbowOne} name="rainbowOne" />
            <RainbowBackground type={RainbowThree} name="rainbowThreeReverse" />
        </div>
    );
};

export default ForParents;
