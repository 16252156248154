import React from "react";
import SecondaryFont from "../components/SecondaryFont";
import RainbowOne from "../images/desktop-rainbow-1.png";
import RainbowTwo from "../images/desktop-rainbow-2.png";
import RainbowThree from "../images/desktop-rainbow-3.png";
import RainbowBackground from "../components/RainbowBackground";
import TitleContainer from "../components/TitleContainer";
import Carousel from "../components/Carousel";
// import Newsletter from "../components/Newsletter";
// import One from "../images/shows/newsletter/one.png";
// import Two from "../images/shows/newsletter/two.png";
// import Three from "../images/shows/newsletter/three.png";
import UpcomingShows from "../components/UpcomingShows";
import ShowOne from "../images/shows/upcoming shows/comedyFestival2023.jpg";
import { Helmet } from "react-helmet";

const Shows = ({
  firstRef,
  firstRefVisible,
  secondRef,
  secondRefVisible,
  thirdRef,
  thirdRefVisible,
}) => {
  // title
  const TITLE = "The Funtones | Shows";

  return (
    <div className="shows">
      <>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
      </>
      <TitleContainer
        firstRef={firstRef}
        firstRefVisible={firstRefVisible}
        text="Shows."
      />
      <div className="sections">
        <section
          ref={firstRef}
          className={firstRefVisible ? "one active" : "one"}
        >
          <div className="contents">
            <SecondaryFont text="Previous Shows." />
          </div>
          <UpcomingShows
            image={ShowOne}
            text="International comedy festival"
            link="https://www.comedyfestival.com.au/2023/shows/the-funtones-show?fbclid=IwAR2SVBAMEWnijO7-excvnKYW1_b_pDm66STZqBG2ACx7smM2JkoO5ZFPX3I"
          />
        </section>
        <section
          ref={secondRef}
          className={secondRefVisible ? "two active" : "two"}
        >
          <div className="contents">
            <SecondaryFont text="Photo Gallery." />
          </div>
          <Carousel />
        </section>
        <section
          ref={thirdRef}
          className={thirdRefVisible ? "three active" : "three"}
        >
          {/*
                    <div className="contents">
                        <SecondaryFont text="Newsletter." />
                    </div>
                   
                    <div>
                        <Newsletter
                            image={One}
                            description="chrismas performance"
                            title="Christmas Performance"
                            date="10th of December 2022"
                            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales. "
                        />
                        <Newsletter
                            image={Two}
                            description="Lorem ipsum"
                            title="Lorem ipsum"
                            date="25th of November 2022"
                            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales. "
                        />
                        <Newsletter
                            image={Three}
                            description="Lorem ipsum"
                            title="Lorem ipsum"
                            date="1st of November 2022"
                            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit magna interdum eu. Curabitur pellentesque nibh nibh, at maximus ante fermentum sit amet. Pellentesque commodo lacus at sodales sodales. "
                        />
                    </div>
    */}
        </section>
      </div>
      <RainbowBackground type={RainbowOne} name="rainbowOne" />
      <RainbowBackground
        type={RainbowTwo}
        name="rainbowTwo"
        style={{ top: "10%" }}
      />
      <RainbowBackground
        type={RainbowTwo}
        name="rainbowTwoReverse"
        style={{ top: "30%" }}
      />
      <RainbowBackground
        type={RainbowTwo}
        name="rainbowTwo"
        style={{ top: "60%" }}
      />
      <RainbowBackground type={RainbowThree} name="rainbowThree" />
    </div>
  );
};

export default Shows;
