import React from "react";

const RainbowBackground = ({ type, name, style }) => {
    return (
        <div>
            <img
                className={name}
                src={type}
                style={style}
                alt="rainbow background"
            />
        </div>
    );
};

export default RainbowBackground;
