import "./styles/main.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import ForParents from "./pages/ForParents";
import Shows from "./pages/Shows";
// import Contact from "./pages/Contact";
import { useEffect, useState } from "react";
import BarLoader from "react-spinners/BarLoader";
import { useInView } from "react-intersection-observer";
import Logo from "./images/logo.png";

function App() {
    // loading spinner
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, []);

    // intersection observer
    const { ref: firstRef, inView: firstRefVisible } = useInView({
        rootMargin: "-100px",
        threshold: 0,
        triggerOnce: true,
    });
    const { ref: secondRef, inView: secondRefVisible } = useInView({
        rootMargin: "-100px",
        threshold: 0,
        triggerOnce: true,
    });
    const { ref: thirdRef, inView: thirdRefVisible } = useInView({
        rootMargin: "-100px",
        threshold: 0,
        triggerOnce: true,
    });
    const { ref: fourthRef, inView: fourthRefVisible } = useInView({
        rootMargin: "-100px",
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <Router>
            <div className="App" style={{ overflow: "hidden" }}>
                {loading ? (
                    <div className="spinner">
                        <img src={Logo} alt="logo" />
                        <BarLoader
                            loading={loading}
                            size={25}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                            color="#ff7fc7"
                        />
                    </div>
                ) : (
                    <div>
                        <Navbar />
                        <div className="content">
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <Home
                                            firstRef={firstRef}
                                            firstRefVisible={firstRefVisible}
                                            secondRef={secondRef}
                                            secondRefVisible={secondRefVisible}
                                            thirdRef={thirdRef}
                                            thirdRefVisible={thirdRefVisible}
                                            fourthRef={fourthRef}
                                            fourthRefVisible={fourthRefVisible}
                                        />
                                    }
                                />
                                <Route
                                    path="/about"
                                    element={
                                        <About
                                            firstRef={firstRef}
                                            firstRefVisible={firstRefVisible}
                                            secondRef={secondRef}
                                            secondRefVisible={secondRefVisible}
                                            thirdRef={thirdRef}
                                            thirdRefVisible={thirdRefVisible}
                                            fourthRef={fourthRef}
                                            fourthRefVisible={fourthRefVisible}
                                        />
                                    }
                                />
                                <Route
                                    path="/foradults"
                                    element={
                                        <ForParents
                                            firstRef={firstRef}
                                            firstRefVisible={firstRefVisible}
                                            secondRef={secondRef}
                                            secondRefVisible={secondRefVisible}
                                            thirdRef={thirdRef}
                                            thirdRefVisible={thirdRefVisible}
                                            fourthRef={fourthRef}
                                            fourthRefVisible={fourthRefVisible}
                                        />
                                    }
                                />
                                <Route
                                    path="/shows"
                                    element={
                                        <Shows
                                            firstRef={firstRef}
                                            firstRefVisible={firstRefVisible}
                                            secondRef={secondRef}
                                            secondRefVisible={secondRefVisible}
                                            thirdRef={thirdRef}
                                            thirdRefVisible={thirdRefVisible}
                                            fourthRef={fourthRef}
                                            fourthRefVisible={fourthRefVisible}
                                        />
                                    }
                                />
                                {/* <Route
                  path="/contact"
                  element={
                    <Contact
                      firstRef={firstRef}
                      firstRefVisible={firstRefVisible}
                      secondRef={secondRef}
                      secondRefVisible={secondRefVisible}
                      thirdRef={thirdRef}
                      thirdRefVisible={thirdRefVisible}
                      fourthRef={fourthRef}
                      fourthRefVisible={fourthRefVisible}
                    />
                  }
                /> */}
                            </Routes>
                        </div>
                        <Footer />
                    </div>
                )}
            </div>
        </Router>
    );
}

export default App;
