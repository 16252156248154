import Carousel from "react-bootstrap/Carousel";
import One from "../images/shows/one.png";
import Two from "../images/shows/two.png";
import Three from "../images/shows/three.png";
import Four from "../images/shows/four.png";

function UncontrolledExample() {
    return (
        <Carousel>
            <Carousel.Item>
                <img className="d-block w-100" src={One} alt="First slide" />
                <Carousel.Caption>
                    {/* <h3>First slide label</h3>
                    <p>
                        Nulla vitae elit libero, a pharetra augue mollis
                        interdum.
                    </p> */}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={Two} alt="Second slide" />

                <Carousel.Caption>
                    {/* <h3>Second slide label</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p> */}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={Three} alt="Third slide" />

                <Carousel.Caption>
                    {/* <h3>Third slide label</h3>
                    <p>
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur.
                    </p> */}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={Four} alt="Fourth slide" />

                <Carousel.Caption>
                    {/* <h3>Third slide label</h3>
                    <p>
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur.
                    </p> */}
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default UncontrolledExample;
