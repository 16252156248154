import React, { useState } from "react";
import ParagraphFont from "./ParagraphFont";
import ThirdFont from "../components/ThirdFont";
import AccordionArrow from "../images/forparents/accordionarrow.png";

const Accordion = ({ firstRef, firstRefVisible }) => {
    const data = [
        {
            question:
                "How do The Funtones' shows & music support childhood development?",
            answer: "The Funtones aim to encourage healthy growth in early childhood development, all while making things fun! Their live shows and music are built around the following key fundamentals: Learning through play, Problem solving and thinking, Using imagination, Engaging in bodily kinesthetics.",
        },
        {
            question:
                "How does The Funtones engage children in their live shows?",
            answer: "All material performed in a live show comes from the mindset of engaging children. We make sure that they feel they always have something to do, and are an integral part of any performance.",
        },
        {
            question:
                "What elements does The Funtones include in their music for children's enjoyment and education?",
            answer: "Our music is designed to be melodically simple, catchy, repetitive, and engaging, which are all key to making great enjoyable, entertaining, and educational music for children. We also aim to bolster self-esteem, promote prosocial behaviour, as well as displaying the importance of inclusivity and inclusion for all.",
        },
    ];

    const [clicked, setClicked] = useState(false);

    const toggle = (index) => {
        if (clicked === index) {
            return setClicked(null);
        }

        setClicked(index);
    };

    return (
        <div
            ref={firstRef}
            className={
                firstRefVisible
                    ? "accordionContainer active"
                    : "accordionContainer"
            }
        >
            {data.map((item, index) => (
                <div className="accordionItem">
                    <div
                        className="accordionQuestion"
                        onClick={() => toggle(index)}
                        key={index}
                    >
                        <ThirdFont text={item.question} />
                        <span>
                            <img
                                src={AccordionArrow}
                                alt="arrow"
                                className={clicked === index ? "" : "active"}
                            />
                        </span>
                    </div>
                    {clicked === index ? (
                        <div className="dropdown">
                            <ParagraphFont text={item.answer} />
                        </div>
                    ) : null}
                </div>
            ))}
        </div>
    );
};

export default Accordion;
