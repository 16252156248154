import React, { useEffect, useState } from "react";

const ThirdFont = ({ text }) => {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 650px)").matches
    );

    useEffect(() => {
        window
            .matchMedia("(min-width: 650px)")
            .addEventListener("change", (e) => setMatches(e.matches));
    }, []);

    return (
        <div>
            {matches && <h1 className="thirdfont">{text}</h1>}
            {!matches && <h1 className="thirdfont mobile">{text}</h1>}
        </div>
    );
};

export default ThirdFont;
