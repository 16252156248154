import React from "react";
import Button from "./Button";
import ThirdFont from "./ThirdFont";
import ParagraphFont from "../components/ParagraphFont";

const UpcomingShows = ({ image, link, text }) => {
    return (
        <div className="upcomingShowsContainer">
            <img className="image" src={image} alt="comedy festival" />
            <div className="information">
                <ThirdFont text={text} />
                <Button type="black" text="view show details" location={link} />
            </div>
            <ParagraphFont
                text="Join Lisa (she/her), Charlie (they/he), Mark (he/him) for lots of singing, dancing, laughs, and heaps of fun in The Funtones Show!
Children will be encouraged to learn through play, think and use their imagination throughout this 45-minute show.
Featuring some comedic skits, original and well-known hit songs you and your children will want to sing and dance along to, such as 'The Air Band', 'Dancing Like Spaghetti', 'The Hokey Pokey' and whole lot more The Funtones Show! is great family fun for all!
"
            />
        </div>
    );
};

export default UpcomingShows;
