import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";

const Navbar = () => {
    const location = useLocation();

    const [mobileMenu, setMobileMenu] = useState(true);

    const handleMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    };

    if (!mobileMenu) {
        (document.body.style.overflow = "hidden") &&
            (document.body.style.maxHeight = "100vh");
    } else {
        document.body.style.overflow = "";
    }

    // intersection observer
    const { ref: navRef, inView: navRefVisible } = useInView({
        rootMargin: "300px",
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <div className={mobileMenu === true ? "navbar" : "navbar active"}>
            <ul ref={navRef} className={navRefVisible ? "active" : ""}>
                <li>
                    <a href="/">
                        <button
                            className={
                                location.pathname === "/"
                                    ? "navButton home active"
                                    : "navButton home"
                            }
                        >
                            home
                        </button>
                    </a>
                </li>
                <li>
                    <a href="/about">
                        <button
                            className={
                                location.pathname === "/about"
                                    ? "navButton about active"
                                    : "navButton about"
                            }
                        >
                            about
                        </button>
                    </a>
                </li>
                <li>
                    <a href="/foradults">
                        <button
                            className={
                                location.pathname === "/foradults"
                                    ? "navButton home active"
                                    : "navButton home"
                            }
                        >
                            for adults
                        </button>
                    </a>
                </li>
                <li>
                    <a href="/shows">
                        <button
                            className={
                                location.pathname === "/shows"
                                    ? "navButton home active"
                                    : "navButton home"
                            }
                        >
                            shows
                        </button>
                    </a>
                </li>
                <li>
                    <a href="mailto:mark@thefuntones.com">
                        <button
                            className={
                                location.pathname ===
                                "mailto:mark@thefuntones.com"
                                    ? "navButton home active"
                                    : "navButton home"
                            }
                        >
                            contact
                        </button>
                    </a>
                </li>
            </ul>
            <div className="burgerMenuContainer">
                <div className="burgerMenu"></div>
                <div
                    className="burgerMenuTrigger"
                    onClick={() => handleMobileMenu() && {}}
                ></div>
            </div>
        </div>
    );
};

export default Navbar;
