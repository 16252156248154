import React from "react";
import SecondaryFont from "../components/SecondaryFont";
import ParagraphFont from "../components/ParagraphFont";
import MarkImage from "../images/about/mark-new-profile.png";
import LisaImage from "../images/about/lisa-new-profile.png";
// import CharlieImage from "../images/about/charlie-new-profile.png";
import RainbowOne from "../images/desktop-rainbow-1.png";
import RainbowTwo from "../images/desktop-rainbow-2.png";
// import RainbowThree from "../images/desktop-rainbow-3.png";
import RainbowBackground from "../components/RainbowBackground";
import TitleContainer from "../components/TitleContainer";
import { Helmet } from "react-helmet";

const About = ({
    firstRef,
    firstRefVisible,
    secondRef,
    secondRefVisible,
    // thirdRef,
    // thirdRefVisible,
}) => {
    // title
    const TITLE = "The Funtones | About";

    return (
        <div className="about">
            <>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
            </>
            <TitleContainer
                firstRef={firstRef}
                firstRefVisible={firstRefVisible}
                text="About us."
            />
            <div className="sections">
                <section
                    ref={firstRef}
                    className={firstRefVisible ? "one active" : "one"}
                >
                    <img src={LisaImage} alt="lisa profile" className="lisa" />
                    <div className="contents">
                        <SecondaryFont text="Meet Lisa!" />
                        <ParagraphFont text="Lisa (she/her) loves to tell jokes and cook spaghetti. She wears the colour green. Outside of The Funtones, Lisa is a Comedian and MC." />
                    </div>
                </section>
                <section
                    ref={secondRef}
                    className={secondRefVisible ? "two active" : "two"}
                >
                    <img src={MarkImage} alt="mark profile" className="lisa" />
                    <div className="contents">
                        <SecondaryFont text="Meet Mark!" />
                        <ParagraphFont text="Mark (he/him) loves to sing and play air guitar. He wears the colour blue. Outside of The Funtones, Mark tutors and teaches singing. He also likes to perform in musicals on stage." />
                    </div>
                </section>
                {/* <section
          ref={thirdRef}
          className={thirdRefVisible ? "three active" : "three"}
        >
          <img src={CharlieImage} alt="charlie profile" className="lisa" />
          <div className="contents">
            <SecondaryFont text="Meet Charlie!" />
            <ParagraphFont text="Charlie (they/he) loves to sing and tap dance. They wear the colour orange. Outside of The Funtones, Charlie performs on stage in plays and musicals." />
          </div>
        </section> */}
            </div>
            <RainbowBackground type={RainbowOne} name="rainbowOne" />
            <RainbowBackground type={RainbowTwo} name="rainbowTwo" />
            <RainbowBackground
                type={RainbowTwo}
                name="rainbowTwoReverse"
                style={{ top: "30%" }}
            />
            <RainbowBackground
                type={RainbowTwo}
                name="rainbowTwo"
                style={{ top: "40%" }}
            />
            {/* <RainbowBackground type={RainbowThree} name="rainbowThree" /> */}
        </div>
    );
};

export default About;
