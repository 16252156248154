import React from "react";
import TitleBackground from "../images/desktop-title-background.png";
import PrimaryFont from "../components/PrimaryFont";

const TitleContainer = ({ firstRef, firstRefVisible, text }) => {
    return (
        <div>
            <div
                ref={firstRef}
                className={
                    firstRefVisible ? "titleContainer active" : "titleContainer"
                }
            >
                <div
                    className="title"
                    style={{ backgroundImage: `url(${TitleBackground})` }}
                >
                    <PrimaryFont text={text} />
                </div>
            </div>
        </div>
    );
};

export default TitleContainer;
